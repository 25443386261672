<template>
  <div class="tw-w-full tw-space-y-4">
    <a-input
      v-model.trim="search"
      ref="search"
      :placeholder="$t('stockIn.searchProductList')"
    />
    <div class="tw-border-solid tw-border-2 tw-rounded-lg tw-p-2">
      <table class="tw-w-full">
        <thead class="tw-border-b tw-border-gray-500 tw-bg-gray-100">
          <tr>
            <th class="tw-py-2 tw-px-1 tw-text-left">
              {{ $t("stockIn.barcode") }}
            </th>
            <th class="tw-py-2 tw-px-1">
              {{ $t("stockIn.productName") }}
            </th>
            <th class="tw-py-2 tw-px-1">
              {{ $t("stockIn.qty") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(pro, index) in this.productList({
              chosenProduct: model.product_detail,
              search: search,
            })"
            :key="index"
            class="tw-cursor-pointer hover:tw-bg-gray-300"
          >
            <td
              @click="addItem(pro)"
              class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-w-1/6"
            >
              {{ pro.barcode }}
            </td>
            <td
              @click="addItem(pro)"
              class="tw-whitespace-nowrap tw-px-2 tw-py-1 tw-w-3/6"
            >
              {{ pro.product_name_en }}
            </td>
            <td class="tw-whitespace-nowrap tw-pl-2 tw-py-1 tw-w-2/6">
              <a-input
                v-model.number="pro.qty"
                @keypress.enter="addItem(pro)"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
export default {
  props: ["value"],
  data() {
    return {
      model: {},
      loading: false,
      search: "",
    };
  },
  created() {
    this.model = this.value;
    this.$nextTick(() => {
      this.$refs.search.focus();
    });
  },
  computed: {
    ...mapState("inventory/stockIn", ["formModels"]),
    ...mapGetters("inventory/stockIn", ["productList"]),
  },
  methods: {
    addItem(product) {
      this.model.product_detail.push({
        product_id: product.product_id,
        uom_id: null,
        qty: product.qty ? product.qty : 1,
        cost: 0,
        amount: 0,
        expiry_date: null,
      });
      this.$nextTick(() => {
        this.$refs.search.focus();
        this.$refs.search.select();
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
